import React, { useEffect, useState } from 'react'
import { searchResources } from '../classes/API'
import { searchArticles } from '../classes/ArticlesAPI'
import { Formik, Form } from 'formik'
import Pagination from './Pagination/Pagination';
import _ from 'lodash'

export default function SuccessStories (data=[])
{
    const [resources, setResources] = useState([])
    const [articles, setArticles] = useState([])
    const [count, setCount] = useState(0)
    const [pages, setPages] = useState(0)
    const [pageNumber, setPageNumber] = useState(1)

    let searchParams = {
        'per-page': 9
    };

    if (data.taxonomy === 'article-type') {
        searchParams['article-type'] = data.termSlug;
    } else {
        searchParams['resource-type'] = data.termId;
    }

    useEffect(() => {
        (async () => {
            if (data.taxonomy === 'article-type') {
                let response = await searchArticles(searchParams)
                let json = await response.json()
                setCount(json.data.count)
                setPages(json.data.max_pages)
                setPageNumber(json.data.current_page)
                setArticles(json.data.articles)
            } else {
                if( typeof data.termId != "undefined" ) {
                    let response = await searchResources(searchParams)
                    let json = await response.json()
                    setCount(json.data.count)
                    setPages(json.data.max_pages)
                    setPageNumber(json.data.current_page)
                    setResources(json.data.resources)
                } else {
                    setCount(data.count)
                    setPages( typeof data.per_page != "undefined" ? data.per_page : searchParams["per-page"] )
                    setPageNumber(data.current_page)
                    setResources(data.resources)
                }
            }
        })()
    }, []);

    async function handleFilterSubmit(opts){

        let args = {}
        _.each(opts, (option, key) => {
            args[key] = option
        })

        args['page'] = pageNumber;
        args['per-page'] = 9;

        if (data.taxonomy === 'article-type') {
            args['article-type'] = data.termSlug;
            let response = await searchArticles(args);
            let json = await response.json()
            setCount(json.data.count)
            setPages(json.data.max_pages)
            setPageNumber(json.data.current_page)
            setArticles(json.data.articles)
        } else {
            args['resource-type'] = data.termId;
            let response = await searchResources(args);
            let json = await response.json()
            setCount(json.data.count)
            setPages(json.data.max_pages)
            setPageNumber(json.data.current_page)
            setResources(json.data.resources)
        }

        window.scrollTo({ top: (document.getElementById('success-stories').offsetTop - 80), behavior: 'smooth' });
    }
    return (
        <div className="success-stories container mx-auto" id="success-stories">
            <Formik initialValues={{}} onSubmit={(values) => handleFilterSubmit(values)}>
                {({handleSubmit, values, setFieldValue}) => (
                <Form>
                    <div className="news-hub mb-12 mt-spacing-6">
                        <Articles articles={data.taxonomy === 'article-type' ? articles : resources} pages={pages} pageNumber={pageNumber} setPageNumber={setPageNumber} handleSubmit={handleSubmit} paginate={data.termId == "undefined"} />
                    </div>
                </Form>
                )}
            </Formik>
        </div>
    )
}

function Articles({articles, pages, pageNumber, setPageNumber, handleSubmit, paginate}){
    return (
        <div className="results">
            <div className="articles-list flex flex-wrap">
                {articles.map(article => (
                    <Article key={article.ID} model={article} />
                ))}
            </div>
            { paginate &&
                <Paginate pages={pages} pageNumber={pageNumber} setPageNumber={setPageNumber} handleSubmit={handleSubmit} />
            }
        </div>
    )
}

function Article({model}){
    const about = (model.author ? 'by ' + model.author + ' • ' : '') + model.date;
    return (
        <a href={model.link} className="article bg-blue-50 p-8 flex flex-col flex-wrap hover:bg-orange-100">
            <h6 className="type-preset-6 text-orange-300 font-bold mb-2">{model.type}</h6>
            <h3 className="type-preset-5 text-ncel-black font-display mb-2 flex-grow" dangerouslySetInnerHTML={{__html: model.title }} />
            <div className="type-preset-7 text-gray-300 font-display" dangerouslySetInnerHTML={{__html: about}} />
        </a>
    )
}

function Paginate({pages, pageNumber, setPageNumber, handleSubmit}){
    if(pages > 1){
        return (
            <div className='pagination news-hub mt-10'>
                <Pagination
                    total={pages}
                    prevText="prev"
                    nextText="next"
                    endSize={1}
                    midSize={4}
                    current={parseInt(pageNumber)}
                    onClickPage={(pageNum) => {
                        setPageNumber(pageNum);
                        handleSubmit();
                    }}
                />
            </div>
        )
    } else {return (<div />)}
}
